<template>
  <base-header-nav-product-link
    :is-mobile-only="$props.isMobileOnly"
    id="dashboard-link-freedome"
    to="/portal/freedome"
    data-track-event="navigate"
    data-track-name="portal-freedome"
    :show-ribbon="false"
    :isHighlighted="isHighlighted"
    product-name="FREEDOME VPN"
    :product-icon="icon"
    product-description="dashboard.freedome_card_desc"
    :product-cta="ctaKey"
    :product-ctas="ctas"
    disabled
    @click.native="openUpgradeOrClose"
    :is-installed="isFreedomeInstalled"
    :licenses-available="freedomeAvailableLicenses"
    :licenses-total="freedomeTotalLicenses"
    :is-dimmed="isDimmed"
    :is-freedome-installable="isFreedomeInstallable"
    :cartType="convertCartType"
    ref="product-link"
  >
  </base-header-nav-product-link>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BaseHeaderNavProductLink from './BaseHeaderNavProductLink.vue'

export default {
  props: {
    isMobileOnly: {
      type: Boolean
    }
  },
  components: {
    BaseHeaderNavProductLink
  },
  data() {
    return {
      icon: require('@/assets/images/icons/icon_freedome.svg')
    }
  },
  computed: {
    ...mapGetters({
      isSafeInstalled: 'customer/licenses/isSafeInstalled',
      isSafeInstallable: 'customer/licenses/isSafeInstallable',
      isFreedomeInstalled: 'customer/licenses/isFreedomeInstalled',
      isFreedomeInstallable: 'customer/licenses/isFreedomeInstallable'
    }),
    ...mapState({
      freedomeTotalLicenses: state =>
        state.customer.licenses.freedomeTotalLicenses,
      freedomeAvailableLicenses: state =>
        state.customer.licenses.freedomeAvailableLicenses,
      cart: state => state.ui.cart
    }),
    ctaKey() {
      if (!this.isFreedomeInstallable) {
        return 'get'
      }

      if (!this.isFreedomeInstalled) {
        return 'install'
      }

      return 'manage'
    },
    ctas() {
      return [
        {
          type: 'get',
          text: this.$t('dashboard.btn_get_freedome')
        },
        {
          type: 'install',
          text: this.$t('dashboard.install_freedome')
        },
        {
          type: 'manage',
          text: this.$t('dashboard.manage_freedome')
        }
      ]
    },
    isHighlighted() {
      return (
        (this.isSafeInstalled || !this.isSafeInstallable) &&
        (!this.isFreedomeInstalled && this.isFreedomeInstallable)
      )
    },
    isDimmed() {
      return !this.isFreedomeInstallable
    },
    convertCartType() {
      if (this.cart.type) {
        return this.cart.type.replace('_', '-')
      }
      return ''
    }
  },
  methods: {
    openUpgradeOrClose() {
      if (!this.isFreedomeInstallable) {
        this.openUpgradePopup()
        return
      }

      // eslint-disable-next-line handle-callback-err
      this.$router.push(this.$refs['product-link'].to).catch(err => {})
      this.closeTopMenu()
    },
    openUpgradePopup() {
      if (this.cart.direct_to_cart) {
        window.location.href = this.cart.direct_to_cart
        return
      }
      this.$root.$emit('toggle_popup', {
        elementId: 'upgrade-popup',
        action: 'open',
        eventInitiator: this.$el
      })
    },
    closeTopMenu() {
      this.$root.$emit('toggle_popup', {
        elementId: 'top-menu',
        action: 'close'
      })
    }
  }
}
</script>
