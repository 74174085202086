<template>
  <div
    data-track-event="navigate"
    data-track-name="fs-help"
    class="the-help"
    :class="{ 'is-open': isOpen }"
    :style="{ height: computedHeight }"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div class="content-wrapper">
      <div class="links">
        <a
          data-track-event="navigate"
          data-track-name="online-manuals"
          target="_blank"
          rel="noopener"
          :href="
            'https://help.f-secure.com/home-products.html#lang=' +
              currentLocaleLanguage
          "
        >
          <span>{{ $t('help.lnk_online_manuals') }}</span>
        </a>
        <a
          data-track-event="navigate"
          data-track-name="contact-us"
          target="_blank"
          rel="noopener"
          :href="
            'https://www.f-secure.com/' + fsecureWebLocale + '/support/contact'
          "
        >
          <span
            >{{ $t('help.lnk_contact_us') }} /
            {{ $t('help.lnk_support') }}</span
          >
        </a>
        <a
          data-track-event="navigate"
          data-track-name="support-services"
          target="_blank"
          rel="noopener"
          :href="'https://www.f-secure.com/' + fsecureWebLocale + '/support'"
        >
          <span>{{ $t('help.lnk_support_services') }}</span>
        </a>
      </div>
      <div class="button" @click.prevent="onClick">
        <picture>
          <img src="../assets/images/icons/icon_customer_support.svg" alt />
        </picture>
        <span>{{ $t('help.btn_f_secure_help') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      openHeight: 0,
      buttonHeight: 0
    }
  },
  computed: {
    computedHeight() {
      return this.isOpen ? this.openHeight + this.buttonHeight + 'px' : ''
    },
    currentLocaleLanguage() {
      return this.$store.state.ui.currentLocale.split('_')[0]
    },
    fsecureWebLocale() {
      const MYFS_TO_FSECURE_WEBPAGE_MAPPINGS = JSON.parse(
        process.env.VUE_APP_MYFS_TO_FSECURE_WEBPAGE_MAPPINGS
      )
      const locale = this.$store.state.ui.currentLocale
      return MYFS_TO_FSECURE_WEBPAGE_MAPPINGS[locale]
    }
  },
  mounted() {
    document.body.addEventListener('click', this.onBodyClick)
  },
  updated() {
    this.openHeight = Math.round(
      this.$el.firstElementChild.getBoundingClientRect().height
    )
    this.buttonHeight = Math.round(this.$el.getBoundingClientRect().height)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.onBodyClick)
  },
  methods: {
    onMouseEnter() {
      this.isOpen = true
    },
    onMouseLeave() {
      this.isOpen = false
    },
    onClick() {
      this.isOpen = !this.isOpen
    },
    onBodyClick(event) {
      if (
        this.isOpen === true &&
        event.target !== this.$el &&
        !this.$el.contains(event.target)
      ) {
        this.isOpen = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.the-help {
  position: absolute;
  width: 145px;
  right: 18px;
  bottom: 67px;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  height: 55px;
  transition: height ease-in-out 200ms;
  overflow: hidden;
  display: none;
  z-index: 2;

  &.is-open {
    height: 200px;
  }

  .content-wrapper {
    padding-top: 9px;
    padding-bottom: 6px;
  }

  .links {
    display: flex;
    flex-direction: column;
    align-content: space-between;

    a {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  a {
    line-height: 1.1em;
    min-height: 40px;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding-left: 18px;
    color: $primary-color;
    padding-right: 12px;
    word-wrap: anywhere;
  }

  a:hover {
    color: $font-color;
  }

  .button {
    color: $font-color;
    padding: 6px 0;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    z-index: 1;
    background: #fff;
    -webkit-user-select: none;
    cursor: pointer;

    span {
      line-height: 1.3rem;
      font-size: 0.9rem;
      padding-right: 10px;
    }

    picture {
      display: inline-block;
      padding: 0 13px 0 17px;
      min-width: 60px;

      img {
        width: 32px;
        height: 40px;
        vertical-align: middle;
      }
    }
  }
}

@media (min-width: $desktop-min-width) {
  .the-help {
    display: block;
  }
}

html[lang='cs'] {
  .the-help {
    height: 65px;

    &.is-open {
      height: 210px;
    }
  }
}
html[lang='ru'] {
  .the-help {
    &.is-open {
      height: 238px;
    }
  }
}
</style>
