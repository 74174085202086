<template>
  <base-header-nav-product-link
    :is-mobile-only="$props.isMobileOnly"
    id="dashboard-link-safe"
    to="/portal/welcome"
    data-track-event="navigate"
    data-track-name="portal-fsecure"
    :show-ribbon="false"
    :isHighlighted="isHighlighted"
    product-name="F-Secure"
    :product-icon="icon"
    product-description="dashboard.safe_card_desc"
    :product-cta="ctaKey"
    :product-ctas="ctas"
    disabled
    @click.native="openUpgradeOrClose"
    :is-installed="isSafeInstalled"
    :licenses-available="safeAvailableLicenses"
    :licenses-total="safeTotalLicenses"
    :is-dimmed="isDimmed"
    :is-safe-installable="isSafeInstallable"
    :cartType="convertCartType"
    ref="product-link"
  >
  </base-header-nav-product-link>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BaseHeaderNavProductLink from './BaseHeaderNavProductLink.vue'

export default {
  props: {
    isMobileOnly: {
      type: Boolean
    }
  },
  components: {
    BaseHeaderNavProductLink
  },
  data() {
    return {
      icon: require('@/assets/images/icons/icon_safe.svg')
    }
  },
  computed: {
    ...mapGetters({
      isSafeInstalled: 'customer/licenses/isSafeInstalled',
      isSafeInstallable: 'customer/licenses/isSafeInstallable'
    }),
    ...mapState({
      safeTotalLicenses: state => state.customer.licenses.safeTotalLicenses,
      safeAvailableLicenses: state =>
        state.customer.licenses.safeAvailableLicenses,
      cart: state => state.ui.cart
    }),
    ctaKey() {
      if (!this.isSafeInstalled) {
        return 'install'
      }

      return 'manage'
    },
    ctas() {
      return [
        {
          type: 'install',
          text: this.$t('dashboard.install_safe')
        },
        {
          type: 'manage',
          text: this.$t('dashboard.manage_safe')
        }
      ]
    },
    isHighlighted() {
      return !this.isSafeInstalled && this.isSafeInstallable
    },
    isDimmed() {
      return !this.isSafeInstallable
    },
    convertCartType() {
      if (this.cart.type) {
        return this.cart.type.replace('_', '-')
      }
      return ''
    }
  },
  methods: {
    openUpgradeOrClose() {
      if (!this.isSafeInstallable) {
        this.openUpgradePopup()
        return
      }

      // eslint-disable-next-line handle-callback-err
      this.$router.push(this.$refs['product-link'].to).catch(err => {})
      this.closeTopMenuAndNavigate()
    },
    closeTopMenuAndNavigate() {
      // eslint-disable-next-line handle-callback-err
      this.$router.push(this.$refs['product-link'].to).catch(err => {})
      this.$root.$emit('toggle_popup', {
        elementId: 'top-menu',
        action: 'close'
      })
    },
    openUpgradePopup() {
      if (this.cart.direct_to_cart) {
        window.location.href = this.cart.direct_to_cart
        return
      }
      this.$root.$emit('toggle_popup', {
        elementId: 'upgrade-popup',
        action: 'open',
        eventInitiator: this.$el
      })
    }
  }
}
</script>
