var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "the-header-bar",
      class: {
        "no-shadow": _vm.$props.noShadow,
        wide: _vm.$props.isWide,
        "even-wider": _vm.$props.isEvenWider,
        "nav-shown": _vm.$props.isNavShown
      }
    },
    [
      _c("the-header-bar-first-section", {
        attrs: {
          isNavShown: _vm.$props.isNavShown,
          "no-menu-link": _vm.noSecondSection,
          "no-top-link": _vm.noTopLink
        }
      }),
      _vm.showSecondSection ? _c("the-header-bar-second-section") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }