<template>
  <div class="the-header">
    <the-header-bar v-bind="$props" />
    <the-header-nav
      v-if="!noSecondSection"
      :is-visible="showTopMenu"
      close-by-esc
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TheHeaderBar from './TheHeaderBar.vue'
import TheHeaderNav from './TheHeaderNav.vue'

export default {
  props: {
    noShadow: {
      type: Boolean,
      default: false
    },
    isWide: {
      type: Boolean,
      default: false
    },
    isEvenWider: {
      type: Boolean,
      default: false
    },
    isNavShown: {
      type: Boolean,
      default: false
    },
    noSecondSection: {
      type: Boolean,
      default: false
    },
    noTopLink: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TheHeaderBar,
    TheHeaderNav
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.customer.isLoggedIn
    }),
    showTopMenu() {
      return !this.$props.noSecondSection && this.isLoggedIn
    }
  }
}
</script>

<style></style>
