<template>
  <base-header-nav-product-link
    is-mobile-only
    id="dashboard-link-idp-monitor"
    to="/portal/idp-portal"
    data-track-event="navigate"
    data-track-name="id-monitoring"
    :product-name="$t('dashboard.id_monitoring')"
    :product-cta="ctaKey"
    :product-ctas="ctas"
    :showLicensesUsed="false"
    disabled
    @click.native="openUpgradeOrClose($event)"
    :is-dimmed="isDimmed"
    ref="product-link"
  >
  </base-header-nav-product-link>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BaseHeaderNavProductLink from './BaseHeaderNavProductLink.vue'

export default {
  components: {
    BaseHeaderNavProductLink
  },
  computed: {
    ...mapGetters({
      isIdmInstallable: 'customer/licenses/isIdmInstallable'
    }),
    ...mapState({
      keyAvailableLicenses: state =>
        state.customer.licenses.keyAvailableLicenses
    }),
    ctaKey() {
      if (!this.isIdmInstallable) {
        return null
      }

      return 'monitor'
    },
    ctas() {
      return [
        {
          type: 'monitor',
          text: this.$t('dashboard.open_id_monitoring')
        }
      ]
    },
    isDimmed() {
      return !this.isIdmInstallable
    }
  },
  methods: {
    openUpgradeOrClose($event) {
      if (!this.isIdmInstallable) {
        this.openUpgradePopup()
        return
      }

      // eslint-disable-next-line handle-callback-err
      this.$router.push(this.$refs['product-link'].to).catch(err => {})
      this.closeTopMenu()
    },
    openUpgradePopup() {
      this.$root.$emit('toggle_popup', {
        elementId: 'upgrade-popup',
        action: 'open',
        eventInitiator: this.$el
      })
    },
    closeTopMenu() {
      this.$root.$emit('toggle_popup', {
        elementId: 'top-menu',
        action: 'close'
      })
    }
  }
}
</script>
