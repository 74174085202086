var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    {
      staticClass: "the-footer",
      class: { "visible-on-mobile": _vm.isVisibleOnMobile }
    },
    [
      _c(
        "a",
        {
          attrs: {
            "data-track-event": "navigate",
            "data-track-name": "terms-of-use",
            href:
              "https://www.f-secure.com/" +
              _vm.fsecureWebLocale +
              "/legal/terms/websites"
          }
        },
        [_vm._v(_vm._s(_vm.$t("footer.lnk_terms_of_use")))]
      ),
      _c(
        "a",
        {
          attrs: {
            "data-track-event": "navigate",
            "data-track-name": "privacy-terms",
            href:
              "https://www.f-secure.com/" +
              _vm.fsecureWebLocale +
              "/legal/privacy/websites"
          }
        },
        [_vm._v(_vm._s(_vm.$t("footer.lnk_privacy_terms")))]
      ),
      _c(
        "a",
        {
          ref: "languageLink",
          staticClass: "icon-link",
          attrs: {
            "data-track-event": "navigate",
            "data-track-name": "language-menu",
            href: "#"
          },
          on: {
            touchstart: _vm.setTouchTrue,
            mouseenter: function($event) {
              return _vm.onLanguageMouseEnter($event, "#region-popup")
            },
            mouseleave: _vm.onLanguageMouseLeave,
            click: function($event) {
              $event.preventDefault()
              return _vm.onLanguageClick($event)
            }
          }
        },
        [
          _c("span", [
            _vm._m(0),
            _c("span", [_vm._v(_vm._s(_vm.currentLocaleName))])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icons/icon-places-blue.svg"),
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }