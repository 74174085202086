var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$props.isVisible,
          expression: "$props.isVisible"
        }
      ],
      staticClass: "the-header-nav",
      class: {
        "is-open": _vm.isOpen,
        "three-cards": !_vm.isSenseCardShown && !_vm.isSenseSdkCardShown,
        "hide-on-desktop": true
      },
      attrs: { id: "top-menu", "data-body-class-on-open": "top-menu-open" },
      on: {
        mouseenter: function($event) {
          return _vm.onMouseEnter("#the-overlay")
        }
      }
    },
    [
      _c(
        "a",
        {
          staticClass: "close",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.closePopup()
            }
          }
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("dashboard.lnk_close").toLowerCase()))
          ]),
          _vm._m(0)
        ]
      ),
      _c(
        "div",
        { staticClass: "content-wrapper" },
        [
          [
            _vm.isIDPSubscription
              ? [
                  _c("key-product-link", { attrs: { "is-mobile-only": "" } }),
                  _vm.showMonitoring
                    ? _c("idp-monitor-link", {
                        attrs: { "is-mobile-only": "" }
                      })
                    : _vm._e()
                ]
              : _vm.isFreedomeSubscription
              ? [
                  _c("freedome-product-link", {
                    attrs: { "is-mobile-only": "" }
                  })
                ]
              : [
                  _c("safe-product-link", { attrs: { "is-mobile-only": "" } }),
                  _vm.showMonitoring
                    ? _c("idp-monitor-link", {
                        attrs: { "is-mobile-only": "" }
                      })
                    : _vm._e()
                ],
            _vm._l(_vm.sharedProductShortNames, function(
              sharedProductShortName
            ) {
              return _c("coupon-share-link", {
                key: sharedProductShortName,
                attrs: { "product-short-name": sharedProductShortName }
              })
            })
          ],
          _c("mobile-logout"),
          _c("mobile-grid-links"),
          _c("mobile-logo")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        attrs: {
          src: require("../assets/images/icons/dashboard_icon_close.svg"),
          alt: "x"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }