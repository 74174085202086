var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "base-view",
      class: {
        "blue-bg": _vm.isBlueBgView,
        "top-menu-open": _vm.isTopMenuOpen,
        "no-top-bottom-padding": _vm.noTopBottomPadding,
        overlayed: _vm.overlayed
      }
    },
    [
      _c("div", { ref: "top", staticClass: "top" }, [_vm._t("top")], 2),
      _c("div", { ref: "bottom", staticClass: "bottom" }, [_vm._t("bottom")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }