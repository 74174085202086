import { render, staticRenderFns } from "./TheHeaderBar.vue?vue&type=template&id=31496a85&scoped=true&"
import script from "./TheHeaderBar.vue?vue&type=script&lang=js&"
export * from "./TheHeaderBar.vue?vue&type=script&lang=js&"
import style0 from "./TheHeaderBar.vue?vue&type=style&index=0&id=31496a85&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31496a85",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/myfs_client/app_myfs_client_prd/buildsources/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31496a85')) {
      api.createRecord('31496a85', component.options)
    } else {
      api.reload('31496a85', component.options)
    }
    module.hot.accept("./TheHeaderBar.vue?vue&type=template&id=31496a85&scoped=true&", function () {
      api.rerender('31496a85', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TheHeaderBar.vue"
export default component.exports