var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "myfs-top-menu-link",
      class: { "is-shown": _vm.isShown, "menu-open": _vm.topMenuOpened },
      attrs: {
        id: "dashboard-toggle-mobile",
        href: "#",
        "data-track-event": "navigate",
        "data-track-name": "total-dashboard"
      },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.toggleTopMenu($event)
        }
      }
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "burger-menu" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icons/icon_burger_menu.svg"),
          alt: ""
        }
      }),
      _c("img", {
        attrs: {
          src: require("@/assets/images/icons/dashboard_icon_close.svg"),
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }