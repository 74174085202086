var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-header-nav-product-link", {
    ref: "product-link",
    attrs: {
      "is-mobile-only": "",
      "is-simple": "",
      to: _vm.toUrl,
      productName: _vm.productName,
      disabled: ""
    },
    nativeOn: {
      click: function($event) {
        return _vm.closeTopMenuAndNavigate($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }