var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "nav-row",
      class: {
        "is-highlighted": _vm.$props.isHighlighted,
        "is-dimmed": _vm.$props.isDimmed,
        "is-mobile-only": _vm.$props.isMobileOnly,
        "is-simple": _vm.$props.isSimple
      },
      attrs: { to: _vm.$props.to, event: _vm.$props.disabled ? "" : "click" }
    },
    [
      !_vm.$props.isMobileOnly
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$props.showRibbon,
                  expression: "$props.showRibbon"
                }
              ],
              staticClass: "ribbon-wrapper"
            },
            [
              _c("div", { staticClass: "ribbon" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("dashboard.not_yet_installed", {
                        line_break: "<br>"
                      })
                    )
                  }
                })
              ])
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "content",
          class: { "no-description": !_vm.$props.productDescription },
          attrs: {
            "data-track-event": _vm.dataTrackEvent,
            "data-track-name": _vm.dataTrackName
          }
        },
        [
          _c("h5", [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$props.productName))
            ]),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.isMobileInstallSuggestionShown &&
                      !_vm.$props.isSimple,
                    expression:
                      "isMobileInstallSuggestionShown && !$props.isSimple"
                  }
                ],
                staticClass: "small-text"
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("dashboard.install_now")))]
            )
          ]),
          !_vm.$props.isMobileOnly
            ? _c("div", { staticClass: "img-wrapper" }, [
                _c("picture", [
                  _c("img", {
                    attrs: {
                      src: _vm.$props.productIcon,
                      alt: _vm.$props.productName,
                      "data-track-event": _vm.dataTrackEvent,
                      "data-track-name": _vm.dataTrackName
                    }
                  })
                ])
              ])
            : _vm._e(),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.$props.isInstalled && !_vm.$props.isSimple,
                  expression: "!$props.isInstalled && !$props.isSimple"
                }
              ],
              staticClass: "description",
              class: { empty: !_vm.$props.productDescription },
              attrs: {
                "data-track-event": _vm.dataTrackEvent,
                "data-track-name": _vm.dataTrackName
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t(_vm.$props.productDescription)) +
                  "\n    "
              )
            ]
          ),
          _vm.$props.licensesTotal && _vm.$props.showlicensesAvailable
            ? _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$props.isInstalled && !_vm.$props.isSimple,
                      expression: "$props.isInstalled && !$props.isSimple"
                    }
                  ],
                  staticClass: "description",
                  attrs: {
                    "data-track-event": _vm.dataTrackEvent,
                    "data-track-name": _vm.dataTrackName
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("dashboard.x_licenses_remaining", {
                          x: _vm.licensesAvailableText
                        })
                      ) +
                      "\n    "
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.$props.isSimple,
              expression: "!$props.isSimple"
            }
          ],
          staticClass: "bottom"
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/images/icons/dashboard_icon_arrow_right.svg"),
              alt: ""
            }
          }),
          !_vm.$props.isMobileOnly
            ? _c(
                "span",
                {
                  staticClass: "button",
                  attrs: {
                    "data-track-event": _vm.dataTrackEvent,
                    "data-track-name": _vm.dataTrackName
                  }
                },
                _vm._l(_vm.$props.productCtas, function(cta) {
                  return _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$props.productCta === cta.type,
                          expression: "$props.productCta === cta.type"
                        }
                      ],
                      key: cta.type,
                      staticClass: "text"
                    },
                    [_vm._v(_vm._s(cta.text))]
                  )
                }),
                0
              )
            : _vm._e(),
          !_vm.$props.isMobileOnly &&
          _vm.$props.extraProductCta &&
          _vm.showIDPWeb
            ? _c(
                "span",
                {
                  staticClass: "button extra",
                  attrs: {
                    "data-track-event": _vm.$props.extraProductDataTrackEvent,
                    "data-track-name": _vm.$props.extraProductDataTrackName
                  }
                },
                _vm._l(_vm.$props.extraProductCtas, function(cta) {
                  return _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$props.extraProductCta === cta.type,
                          expression: "$props.extraProductCta === cta.type"
                        }
                      ],
                      key: cta.type,
                      staticClass: "text extra",
                      attrs: {
                        id: cta.type,
                        "data-track-event":
                          _vm.$props.extraProductDataTrackEvent,
                        "data-track-name": _vm.$props.extraProductDataTrackName
                      }
                    },
                    [_vm._v(_vm._s(cta.text))]
                  )
                }),
                0
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }