var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "the-header" },
    [
      _c("the-header-bar", _vm._b({}, "the-header-bar", _vm.$props, false)),
      !_vm.noSecondSection
        ? _c("the-header-nav", {
            attrs: { "is-visible": _vm.showTopMenu, "close-by-esc": "" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }