var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-header-nav-row",
    { staticClass: "logo mobile-only" },
    [_c("myfs-logo")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }