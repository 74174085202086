import { render, staticRenderFns } from "./TheHeaderNavKeyProductLink.vue?vue&type=template&id=24bff19f&"
import script from "./TheHeaderNavKeyProductLink.vue?vue&type=script&lang=js&"
export * from "./TheHeaderNavKeyProductLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/myfs_client/app_myfs_client_prd/buildsources/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24bff19f')) {
      api.createRecord('24bff19f', component.options)
    } else {
      api.reload('24bff19f', component.options)
    }
    module.hot.accept("./TheHeaderNavKeyProductLink.vue?vue&type=template&id=24bff19f&", function () {
      api.rerender('24bff19f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TheHeaderNavKeyProductLink.vue"
export default component.exports