var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-header-nav-product-link", {
    ref: "product-link",
    attrs: {
      "is-mobile-only": _vm.$props.isMobileOnly,
      id: "dashboard-link-safe",
      to: "/portal/welcome",
      "data-track-event": "navigate",
      "data-track-name": "portal-fsecure",
      "show-ribbon": false,
      isHighlighted: _vm.isHighlighted,
      "product-name": "F-Secure",
      "product-icon": _vm.icon,
      "product-description": "dashboard.safe_card_desc",
      "product-cta": _vm.ctaKey,
      "product-ctas": _vm.ctas,
      disabled: "",
      "is-installed": _vm.isSafeInstalled,
      "licenses-available": _vm.safeAvailableLicenses,
      "licenses-total": _vm.safeTotalLicenses,
      "is-dimmed": _vm.isDimmed,
      "is-safe-installable": _vm.isSafeInstallable,
      cartType: _vm.convertCartType
    },
    nativeOn: {
      click: function($event) {
        return _vm.openUpgradeOrClose($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }