<template>
  <base-header-nav-row class="grid mobile-only">
    <router-link
      id="mobile-subscription-link"
      data-track-event="navigate"
      data-track-name="subscription"
      to="/portal/order"
      v-if="!isSubUser"
      @click.native="closeTopMenu"
    >
      <span>{{ $t('dashboard.lnk_subscription') }}</span>
    </router-link>
    <a
      id="mobile-support-link"
      data-track-event="navigate"
      data-track-name="support-services"
      :href="'https://www.f-secure.com/' + fsecureWebLocale + '/support'"
    >
      <span>{{ $t('dashboard.lnk_support_services') }}</span>
    </a>
    <router-link
      id="mobile-account-link"
      data-track-event="navigate"
      data-track-name="account-details"
      to="/portal/contact"
      @click.native="closeTopMenu"
    >
      <span>{{ $t('dashboard.lnk_account') }}</span>
    </router-link>
    <a
      id="mobile-terms-of-use-link"
      data-track-event="navigate"
      data-track-name="terms-of-use"
      :href="
        'https://www.f-secure.com/' + fsecureWebLocale + '/legal/terms/websites'
      "
    >
      <span>{{ $t('dashboard.lnk_terms_of_use') }}</span>
    </a>
    <a
      id="mobile-privacy-link"
      data-track-event="navigate"
      data-track-name="privacy-terms"
      :href="
        'https://www.f-secure.com/' +
          fsecureWebLocale +
          '/legal/privacy/websites'
      "
    >
      <span>{{ $t('dashboard.lnk_privacy_terms') }}</span>
    </a>
    <a
      id="mobile-language-link"
      href="#"
      data-track-event="navigate"
      data-track-name="language-menu"
      @click.prevent="openRegionPopup"
      class="icon-link black-to-gray-image dark-text"
    >
      <span>
        <span class="icon h24">
          <img src="@/assets/images/icons/icon-places.svg" alt />
        </span>
        <span>{{ currentLocaleName }}</span>
      </span>
    </a>
  </base-header-nav-row>
</template>

<script>
import BaseHeaderNavRow from './BaseHeaderNavRow.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    BaseHeaderNavRow
  },
  computed: {
    ...mapState({
      currentLocaleName: state => state.ui.currentLocaleName
    }),
    fsecureWebLocale() {
      const MYFS_TO_FSECURE_WEBPAGE_MAPPINGS = JSON.parse(
        process.env.VUE_APP_MYFS_TO_FSECURE_WEBPAGE_MAPPINGS
      )
      const locale = this.$store.state.ui.currentLocale
      return MYFS_TO_FSECURE_WEBPAGE_MAPPINGS[locale]
    },
    ...mapGetters({
      isSubUser: 'customer/contract/isSubUser'
    })
  },
  methods: {
    openRegionPopup() {
      this.$root.$emit('toggle_popup', {
        elementId: 'region-popup',
        action: 'open',
        eventInitiator: this.$el
      })
    },
    closeTopMenu() {
      this.$root.$emit('toggle_popup', {
        elementId: 'top-menu',
        action: 'close'
      })
    }
  }
}
</script>

<style lang="scss">
.nav-row {
  &.grid {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;

    a {
      text-decoration: none;
      padding: 15px 30px;
      text-align: center;
      position: relative;
      display: flex;
      color: $font-color;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      min-height: 54px;

      span {
        line-height: 1.3rem;
      }

      &:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
      }

      &:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
      }

      &:nth-child(3) {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
      }

      &:nth-child(4) {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
      }

      &:nth-child(5) {
        -ms-grid-row: 2;
        -ms-grid-column: 2;
      }

      &:nth-child(6) {
        -ms-grid-row: 2;
        -ms-grid-column: 3;
      }

      &:after {
        content: '';
        display: block;
        height: 100%;
        width: 1px;
        position: absolute;
        top: 0;
        right: 0;
        background-image: linear-gradient(#e9e9e9, #e9e9e9);
        background-size: 1px 25px;
        background-repeat: no-repeat;
        background-position: center;
      }

      &:nth-child(3n)::after {
        content: none;
      }
    }

    &::before,
    &::after {
      content: '';
      width: 100%;
      background: #e9e9e9;
      height: 1px;
      grid-column-start: 1;
      grid-column-end: 4;
      position: relative;
      grid-row: 2;
    }

    &::after {
      display: none;
    }
  }

  &.grid a:nth-child(1),
  &.grid a:nth-child(2),
  &.grid a:nth-child(3) {
    color: $primary-color;
  }
}

@media (max-width: 580px) {
  .nav-row.grid {
    grid-template-columns: 1fr 1fr;

    &::before {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &::after {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row: 4;
      display: block;
    }

    a:nth-child(2n + 1)::after {
      content: '';
    }

    a:nth-child(2n + 2)::after {
      content: none;
    }

    a {
      &:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
      }

      &:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
      }

      &:nth-child(3) {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
      }

      &:nth-child(4) {
        -ms-grid-row: 2;
        -ms-grid-column: 2;
      }

      &:nth-child(5) {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
      }

      &:nth-child(6) {
        -ms-grid-row: 3;
        -ms-grid-column: 2;
      }
    }
  }
}

@media (max-width: 374px) {
  .nav-row.grid {
    grid-template-columns: 1fr;

    &::before,
    &::after {
      content: none;
    }

    a {
      border-bottom: 1px solid #e9e9e9;
    }
  }
}
</style>
